<template>
    <div class="box">
        <div class="box_container">
            <div style="height:10px;"></div>
            <!-- 下拉框 -->
            <div class="head">
                <div></div>
                <div class="flex">
                    <div class="flex head_dropDown">
                        <span>发送人</span>
                        <el-select class="head_select" v-model="userId" filterable placeholder="请选择发送人">
                            <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name"
                                :value="item.User_ID">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex head_dropDown">
                        <span>类型</span>
                        <el-select class="head_select" v-model="radiobroadcastType" filterable placeholder="请选择类型">
                            <el-option v-for="item in radiobroadcastData" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex head_dropDown">
                        <span>日期</span>
                        <el-date-picker :picker-options="pickerOptions" v-model="valueStart" type="datetimerange" range-separator="~"
                            start-placeholder="开始范围" end-placeholder="结束范围" align="right">
                        </el-date-picker>
                    </div>
                    <div class="head_search head_dropDown mouse" @click="search()">
                        搜索
                    </div>
                    <div class="head_but mouse" @click="dowloan()">
                        批量下載
                    </div>
                </div>
            </div>
            <!-- 下拉结束 -->
            <!-- 表格 -->
            <div class="table table_one">
                <history-table :bool="bool" :tableData="tableData" @openPlay="openPlay" :tableHead="tableHead"
                    ref="history" @getSelectiondata='getSelectiondata' >
                    <!-- :height="styleHeight" -->
                    <el-table-column slot="operation" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="table_box">
                                <span class="table_but mouse" @click="download(scope.row)">下载</span>
                            </div>
                        </template>
                    </el-table-column>
                </history-table>
            </div>
            <!-- 表格结束 -->
            <!-- 分页 -->
            <div class="pagination">
                <el-pagination class="pagination_bottom" :current-page="paging.pageNo" background
                    :page-sizes="[20, 40, 60, 80]" layout="total,prev, pager, next,jumper,slot, sizes"
                    @size-change="sizeChange" @prev-click="prevClick" @next-click="nextClick"
                    @current-change="currentChange" :total="paging.total">
                    <span class="confirbtn" @click="comfir()">确定</span>
                </el-pagination>
            </div>
        </div>
        <dowloanpop :showcontent='showcontent' :tipstext='tipstext' @confirmclosepop='confirmclosepop'
            @closepop='closepop'></dowloanpop>
    </div>
</template>

<script src=""></script>
<script>
import {queryBroadcastRecord,queryUserName} from "@/administration/history.js";
import historyTable from '@/components/history/table.vue';
import dowloanpop from '@/components/history/deletepop.vue';
import beasconfig from '@/api/beasconfig.js';
import EventBus from "@/eventBus/eventBus.js";
// import axios from 'axios';
const userState = JSON.parse(sessionStorage.getItem("userState"));
export default {
  components: { 
    historyTable,
    dowloanpop
    },
    // props: {

    // },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    let curDate = (new Date()).getTime();
                    let three = 90 * 24 * 3600 * 1000;
                    let threeMonths = curDate - three;
                    return time.getTime() > Date.now() || time.getTime() < threeMonths;
                }
            },
            valueStart:'',  //开始时间
            user:[     //用户
                {
                    value:1,
                    label:'群组1'
                }
            ],
            userId:'',   //用户id
            stateData:[     //状态
                {
                    value:-1,
                    label:'请选择状态'
                },
                {
                    value:0,
                    label:'未处理'
                },
                {
                    value:2,
                    label:'已处理'
                }
            ],
            state:'',
            radiobroadcastData:[    //广播类型
                {
                    value:-1,
                    label:'请选择类型'
                },
                {
                    value:1,
                    label:'文字广播'
                },
                {
                    value:3,
                    label:'语音广播'
                }
            ],
            radiobroadcastType:'',
            currentPage4: 4,
            paging:{
                total:0,
                currentPage:1,
                pageNo:1,
                pageSize:20
            },
            sos:{
                clUser:'',  //发送人
                fsUser:'',  //处理人
                fsStartTime:'', //发送开始时间
                clStartTime:'' //处理结束时间  
            },
            // sos
            userInfo:{}, //用户信息
            chooselist: [],     //选中数据
            tableData:[],    //table数据
            tableHead:[{     //表头
                        lable:'发送人',
                        prop:'fromName',
                        width:'300px'
                        },
                        {
                        lable:'日期',
                        prop:'SEND_TIME',
                        width:'220px'
                        },
                        {
                        lable:'类型',
                        prop:'MSG_TYPE',
                        width:'130px'
                        },
                        {
                        lable:'内容',
                        prop:'MSG'
                        }],       //表头数据
            bool:false,
            music:null,
            showcontent: false,
            tipstext: '确定下载所选的广播记录吗？',
            ossMap:{

            },
            excelData:{
                ly:[],
                wb:[]
            },
            timeNum: 0,
            temporaryTime: null,
            styleHeight:'height: calc(100vh - 73px - 145px);'
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryUserName();
        this.getQueryBroadcastRecord();
    },
    beforeCreate: function () { },
    beforeDestroy(){
        let i = this.tableData.findIndex(item => item.bool == true);
        if(i >= 0){
            this.eliminateTime();
            this.$set(this.tableData[i], "bool", false);
            // console.log(this.$refs.history)
            // this.$refs.history.pause()
            // this.$refs.history.music = null;
        }
     },
    mounted() {

    },
    methods: {
        openPlay(row, bool,type) {   //关闭声音
                let index = this.tableData.findIndex(item => item.MSG_UUID == row.MSG_UUID);
                if(type){   //停止讲话
                    this.eliminateTime();
                }
                if (bool) {
                    this.startTime(row);
                    this.$set(this.tableData[index], "bool", true);
                } else {
                    this.eliminateTime();
                    this.$set(this.tableData[index], "bool", false);
                }
        },
        eliminateTime() {    //清除时间
            clearInterval(this.temporaryTime);
            this.timeNum = 0;
            this.temporaryTime = null;
        },
        startTime(row) {
            //开始计时
            let that = this;
            let num = 0;
            let index = that.tableData.findIndex(item => item.MSG_UUID == row.MSG_UUID);
            function timerTow() {
                return setInterval(() => {
                num++;
                that.timeNum = num;
                if (that.timeNum >= Math.abs(row.MSG / 1000)) {
                    EventBus.$emit('stopPlayGb');
                    that.$set(that.tableData[index], "bool", false);
                    // that.$refs.history.music = null;
                    that.eliminateTime();
                }
                }, 1000);
            }
            that.temporaryTime = timerTow();
         },
        getQueryBroadcastRecord(){     //广播记录
            let that = this;
            let data = {
                uId:that.userInfo.id,
                pageNo:that.paging.pageNo,
                pageSize:that.paging.pageSize
            }
             if( this.valueStart !='' && this.valueStart !=null){  //开始时间
                data.startAt = this.$moment(this.valueStart[0]).valueOf();
                data.endAt = this.$moment(this.valueStart[1]).valueOf();
            } 
            if(that.userId != '' && that.userId > 0){ //发送人
                data.sendId = that.userId;
            }
            if(that.radiobroadcastType != '' && that.radiobroadcastType > 0){   //广播类型
                data.msgType = that.radiobroadcastType;
            }
            queryBroadcastRecord(data).then(res =>{
                    if(res.data.code == 200){
                        console.log(res)
                            that.paging.total = res.data.data.count;
                            let list = res.data.data.list;
                             if(list.length > 0){
                                list.forEach(item => {
                                    item.SEND_TIME = this.$moment(item.SEND_TIME).format("YYYY-MM-DD HH:mm:ss");
                                });
                                that.tableData = list;
                            }else{
                                that.tableData = [];
                            }
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        getQueryUserName(){ //获取用户下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryUserName(data).then(res=>{
                   if(res.data.code == 200){
                        that.user = res.data.data;
                        that.user.unshift({
                            User_ID:-1,
                            User_Name:'请选择发送人'
                        })
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        search(){      //搜索
            this.paging.pageNo = 1;
            this.$refs.history.parseAudio();
            this.refresh();
        },
        download(row){ //下载
            let that = this;
                if(row.MSG_TYPE != 1){
                    var filePath = `${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/download?user=${row.fromId}&uuid=${row.MSG_UUID}`;
                    fetch(filePath).then(res => res.blob()).then(blob => {
                                        const a = document.createElement('a')
                                        document.body.appendChild(a)
                                        a.style.display = 'none'
                                        // 使用获取到的blob对象创建的url
                                        const url = window.URL.createObjectURL(blob)
                                        console.log("url", url);
                                        a.href = url
                                        // 指定下载的文件名
                                        a.download = `${row.MSG_UUID}.wav`
                                        a.click()
                                        document.body.removeChild(a)
                                        // 移除blob对象的url
                                        window.URL.revokeObjectURL(url)
                                })
                 }else{
                    // that.excelData.wb = [row];
                    that.downloadText(row);
                 }
        },
         downloadText(row) {   //下载文本
                let modelCode = row.MSG;
                let export_blob = new Blob([modelCode]);
                let save_link = document.createElement("a");
                save_link.href = window.URL.createObjectURL(export_blob);
                save_link.download = row.fromName + '.txt';
                let ev = document.createEvent("MouseEvents");
                ev.initMouseEvent(
                    "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
                );
                save_link.dispatchEvent(ev);
        },
        getSelectiondata(data) {        //选中的数据
             this.chooselist = data;
             this.excelData = {
                wb:data.filter(item => item.MSG_TYPE == 1),
                ly:data.filter(item => item.MSG_TYPE != 1)
             }
        },
        dowloan() {     //批量下载
                let excelData = this.excelData;
                if(excelData.wb.length > 0 || excelData.ly.length > 0){
                    this.showcontent = true;
                }else {
                    this.$MessageWarning('请选择需要下载的广播记录！');
                }
        },
         closepop() {
            this.showcontent = false
        },
        confirmclosepop() {     //批量下载
             let excelData = this.excelData;
             this.showcontent = false;
                if(excelData.ly.length > 0){
                            excelData.ly.forEach(item =>{
                                var filePath = `${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/download?user=${this.userInfo.id}&uuid=${item.MSG_UUID}`;
                                console.log(filePath)
                                fetch(filePath).then(res => res.blob()).then(blob => {
                                        const a = document.createElement('a')
                                        document.body.appendChild(a)
                                        a.style.display = 'none'
                                        // 使用获取到的blob对象创建的url
                                        const url = window.URL.createObjectURL(blob)
                                        console.log("url", url);
                                        a.href = url
                                        // 指定下载的文件名
                                        a.download = `${item.MSG_UUID}.wav`
                                        a.click()
                                        document.body.removeChild(a)
                                        // 移除blob对象的url
                                        window.URL.revokeObjectURL(url)
                                })
                            })
                     } 
                     if(excelData.wb.length > 0){
                        // this.$refs.downloadntb.generate();
                        this.excelData.wb.forEach(row =>{
                            this.downloadText(row);
                        })
                     }
                    // this.excelData.wb = [];
                    // this.excelData.ly = [];
        },
        refresh(){      //刷新
              this.getQueryBroadcastRecord();
        },
        comfir() {  //确定按钮
                this.refresh();
            },
        sizeChange(pageSize) {
                //当前页数据发生改变时
                this.paging.pageSize = pageSize;
                this.refresh();
            },
            prevClick(pageNum) {
                //前进按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            nextClick(pageNum) {
                //退后按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            currentChange(pageNum) {
                //当前页发生改变时
                this.paging.pageNo = pageNum;
                this.refresh();
            }
    },
    watch: {
        //监听事件
        radiobroadcastType(val) {
            // console.log(val == -1)
            if (val == -1) {
                this.radiobroadcastType = '';
            }
        },
        userId(val){
            if (val == -1) {
                this.userId = '';
            }
        }
    },
};
</script>

<style scoped lang="less">
.box {
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #ffffff;

    &_container {
        width: calc(100% - 20px);
        height: 100%;
        margin: auto;

        .head {
            width: 100%;
            padding: 10px 0px;
            background-color: #f0f0f0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 15px;
            color: #5a5655;

            &_container {
                flex: 1;
                margin-left: 10%;
            }

            &_dropDown {
                font-size: 15px;
                color: #5a5655;
                margin-right: 20px;

                span {
                    margin-right: 5px;
                }
            }

            &_select {
                width: 160px;
                height: 40px;
            }

            &_picker {
                width: 280px !important;
                text-align: center;
            }

            &_search {
                width: 70px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #4d4d4d;
                background-size: 100% 100%;
                border-radius: 5px;
                color: #ffffff !important;
            }

            &_but {
                width: 110px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #327677;
                border-radius: 3px;
                color: #ffffff !important;
                margin-right: 5px;
            }
        }

        .table_one {
            height: calc(100vh - 73px - 145px);
        }

        // .table_tow {
        //     height: calc(100% - 235px);
        // }

        .table {
            width: 100%;
            background-color: #f0f0f0;
            overflow: auto;
            color: #000000;

            &_box {
                width: 100%;
                text-align: center;
            }

            &_but {
                color: var(--main-color);
                margin-right: 10px;
            }
        }

        .pagination {
            width: 100%;
            // height: 80px;
            text-align: left;
            position: relative;
            background-color: #ffffff;

            /deep/.el-pagination__jump {
                margin-left: 0;
            }

            .tip-sos {
                text-align: center;
                color: #b2b2b2;
                height: 30px;
                line-height: 30px;
            }

            &_bottom {
                padding: 15px 0px;
                // position: absolute;
                // bottom: 0px;
                // left: 0px;
            }

            .confirbtn {
                background-color: #5a5657;
                padding: 0 6px;
                color: #ffffff;
                margin-left: 4px;
                cursor: pointer;
                border-radius: 2px;
                // line-height: 14px;
            }
        }
    }
}

.video-box {
    width: 100%;
    height: 480px;
}

vidio {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bg_one {
    color: #ffffff;
    background: url("../../assets/history/select.png");
}

.bg_tow {
    color: #000000;
    background: url("../../assets/history/select1.png");
}

.flex {
    display: flex;
    align-items: center;
}
</style>